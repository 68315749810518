<template>

<div v-show="popShowing" class="popContainer">

<div  v-show="settingShowing" style="position: relative; text-align: left;" >

  <i @click="hidePop()" :class="{ negro: config.usuario.crearUserIsShowing}" style="position: absolute; top: 5px; right: 10px;" class="bi bi-x-lg"></i>

  <div v-if="config.usuario.crearUserIsShowing">
     <CrearUsuario />
  </div>

  <div v-if="config.configDevice.configDeviceIsShowing">
    <ConfigDispositivo :datosCajeros="datosCajeros"/>
  </div>


</div>

<section  v-show="!settingShowing" style="padding-bottom:50px;" class="widget"><!---->
  <div v-show="!trackerSettingShowing">
    



                  <header class="title" style="position: relative;">
                      <i @click="hidePop()" style="position: absolute; top: 5px; right: 10px;" class="bi bi-x-lg"></i>
                    <h5 style="color: white; margin-bottom: 20px;">
                    <span v-show="!settingShowing">Lista de las Alertas por {{detallesPop.tituloPopBat}}</span>                     
                   </h5>
                  </header><!----><!---->



                  <div class="widgetBody widget-body widget-table-overflow">
                    <div class="table-responsive contenedorTabla">
                      <table class="table table-striped table-lg mb-0 requests-table">
                        <thead>
                          <tr class="columnaName">
                            <th>N°</th>
                            <th>Fecha</th>
                            <th>mensaje</th>
                            <th>descripcion</th>
                            <th>ubicacion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for=" (dato, index) in tablaShowing" :key="index" class="hovertabla">
                            <td>{{index+1}}</td>
                            <td>{{ new Date(dato.time).toLocaleDateString()+" "+new Date(dato.time).toLocaleTimeString('en-US')}}</td>
                            <td>{{dato.message}}</td>
                            <td>{{dato.description}}</td>
                            <td><i @click="verUbicacion(dato.lat,dato.lng)" class="bi bi-geo-alt"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
  </div>
</section>


</div>

<div class="d-flex pagina">
<div id="mySidebar" class="sidebar text-white d-flex flex-column flex-shrink-0" style="position: relative;">

  <div v-if="isOpen">
  <i @click="openClose()"  class="bi bi-x cerrarSideBar"></i>

<img src="../assets/logo.png" class="img-fluid mt-5 mb-3" alt="Responsive image" width="220">      
      <ul class="nav nav-pills flex-column mb-auto" >
      <li @click="dashboard()" class="menuTitles">
        <a href="#" class="nav-link text-white"><i :class="{ pestanaActual: !dashboardShowing}"  class="bi bi-ui-checks-grid"></i> <span :class="{ pestanaActual: !dashboardShowing}" >Dashboard</span></a>
      </li>
        <li @click="alerts()" class="menuTitles">
        <a href="#" class="nav-link text-white">
        <i :class="{ pestanaActual: !alertShowing}" class="bi bi-bell"></i>
        <span class="mlMenu" :class="{ pestanaActual: !alertShowing}" >Alertas</span></a>
      </li>
        <li @click="historico()" class="menuTitles">
         <a href="#" class="nav-link text-white">
        <i class="bi bi-bookmark-dash" :class="{ pestanaActual: !historicoShowing}" ></i>
        <span class="mlMenu" :class="{ pestanaActual: !historicoShowing}" >Reporte</span></a>
      </li>

        <li class="menuTitles">
        <a href="#" class="nav-link text-white">

          <div :class="{ pestanaActual: !config.labelConfigShowing}" @click="setting()" data-toggle="collapse" class="mlMenu"  href="#multiCollapseExample1"  aria-expanded="false" aria-controls="multiCollapseExample1" > <i  class="bi bi-gear"></i> Configuracion <i class="bi bi-chevron-down"></i></div>
        </a>
          <div class="collapse multi-collapse" id="multiCollapseExample1">
             <div  style="cursor: pointer;" :class="{ pestanaActual: !config.usuario.label}" @click="crearUsuarioMenu()" >Usuario</div>
             <div  style="cursor: pointer;" :class="{ pestanaActual: !config.configDevice.label}" @click="settingDeviceMenu()" >Dispositivo</div>
          </div>

    
      </li>
    </ul> 

    <div class="logoAurora">
      <div>Powered by</div>
      <img src="../assets/auroraLogo.png" class="img-fluid" alt="Responsive image" width="200"> 
    </div>      

  </div>

  <div v-if="!isOpen" class="flex-column mb-auto">
<img  @click="openClose()" class="iconPopular" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAb1BMVEXy+fvv8fX///8CLW8AJGrp7PIQMG8SOnYZRHwWUYUXXI0haZYmeaEohKkuk7NPnLl80+BfzNtAx9g5wdOxwtOBtcq24+suv9LO5+4nuM2b3ec4U4Usob3Fz9zd8vZqfaGdrMNQapRnvNCCkK4ABF+zWkNBAAAAAnRSTlP/+SHWevIAAAHASURBVHgBfdOHoqMgFEVR5lBTLCgqNpRH/v8b55KeTNnpyRKIhbFf/w3sF/9fQhJQOU0Z6vCRfgJ9OJ7ORVnVtqF729w7PoEhULiyQz8wP062vfYOSHh0FfoWU0PsG+jZnyv4hfkGdkJTs+ZrBAl/XLEEb9nAaJ4wDO+A8w1wBbop1MFi8GGsQ/MCYnc6MlQu/Ex26qeaDTX8G5gBGU+MldWytIO1oelRj9Y+gU4S2M9dUS7L2NLk3rfWY2ofIIrL5rEfaVcRoGrrw9SM9xH4DLamQzydCNxE09vBTsHegNiQc4erWLIYah+C7+9AmbiltKfr4biKcWiaYRzH9gnitqXNZHHugqQbQ276nIITIJHXsXjk6sNjkfD7PBvzEgy5nxtQelOXuNIQD1FUARRb7kDxlIfc5iyInDpfdAxYC3MDeQ54k5C4pnPu6ABZnR2qs3keLLhLAqJb533dVlDdsTg9gTIp/+4MYBhcRM7RZK+TVmwAE44eEoznIVj8OKvVLJE4g7xIYLse/nwVvAGhd5GAlQA9ravhmlJPkInYJa2VAdJwoa69A0qoGEVKUSvqAzwTnAYS6hOI/0UA8r/hN/q2K/nW721DAAAAAElFTkSuQmCC" >
<div @click="dashboard()" style="margin-top: 30px;"><i :class="{ pestanaActual: !dashboardShowing}" class="bi bi-ui-checks-grid"></i></div>
<div @click="historico()" class="iconos"> <i :class="{ pestanaActual: !historicoShowing}" class="bi bi-bookmark-dash"></i></div>
<div @click="setting()" class="iconos"><i class="bi bi-gear"></i></div>
  </div>
</div>

<!-- hola -->

<!-- area trabajo -->
<div class="areaTrabajo ">
<div class="container-fluid mediciones mt-5">

<div class="container-fluid">
<div class="row">

<div class="col-12" style="  padding-left:30px;
  padding-right:40px;">

<div class="rounded-pill" style="float: left; padding-left: 10px; padding-bottom: 3px; padding-top: 3px; padding-right: 10px; background-color: #1e1f24;  border:1px solid rgba(42, 41, 0, 0.7);">
<input  placeholder="    Buscar..." type="text" style=" background-color: #1e1f24; color: grey; outline: none;   border:none"> 
 </div> 

<div>
<i class="bi bi-bell" style="float: right; color: grey;"><img src="../assets/user.png" style="margin-left: 20px; color: grey;">
<span style="color: white;">{{datosUser.userName}}</span>
<br>
<sup style="float: right;">
  
  <a @click="logOut()">
<div class="bi bi-door-open menu menuLogOut"> Log Out</div>
</a>
</sup>
</i>
</div>
</div>

<div class="col-12 targetasContainer" style="margin-top: 10px;">

<!-- targeta 1 -->
 <div class="targetas marco">
<div class="graficosIcons">
  <img src="../assets/verde.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">
</div>

<div style="float: left;">
<strong>Unidades Activas</strong>
<li :style="{ color: color('verdeClaro')[0]}" style="margin-left: -30%;"></li>
<h3 style="float: left;"><strong>{{datos.trackers_db-datos.trackersOffline}}</strong><sub>/{{datos.trackers_db}}</sub></h3>
</div>
</div>

<!-- targeta 2 -->
<div class="targetas marco">
<div class="graficosIcons">
<img src="../assets/rojo.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">
</div>

<div style="float: left;"><strong>Unidades Sin Reportar</strong>
<li :style="{ color: color('rosado')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>{{datos.trackersOffline}}</strong><sub>/{{datos.trackers_db}}</sub></h3>
</div>
</div>

<!-- targeta 3 -->
<div @click="showPopBate()" class="targetas marco">
<div class="graficosIcons">
<img src="../assets/azul.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Evento Low Batery</strong>
<li :style="{ color: color('azulMarino')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>{{datos.lowBattCount}}</strong></h3>
</div>
</div>

<!-- targeta 4 -->
<div @click="showPopMove()" class="targetas marco">
<div class="graficosIcons">
<img src="../assets/violeta.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Sensor movimiento</strong>
<li :style="{ color: color('violeta')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>{{datos.movementCount}}</strong></h3>
</div>
</div>

<!-- targeta 5 -->
<div @click="showPopTodo()" class="targetas marco">
<div class="graficosIcons">
<img src="../assets/amarillo.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Eventos</strong>
<li :style="{ color: color('amarillo')[0]}"> </li>
<h3 style="float: left;"><strong>{{datos.eventosCount}}</strong></h3>
</div>
</div> 

<!-- fin targetas -->




</div>
</div>
</div>




</div>

<div @click="hidePop()" v-show="alertShowing" >
 <div class="container-fluid mediciones">

<div class="container-fluid">
<div class="row">
<div class="col-12 targetasContainer" style="margin-top: 10px;">

<!-- targeta 1 -->
 <div class="targetas marco">
<div class="graficosIcons">
  <img src="../assets/verde.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">
</div>

<div style="float: left;">
<strong>Unidades Activas</strong>
<li :style="{ color: color('verdeClaro')[0]}" style="margin-left: -30%;"></li>
<h3 style="float: left;"><strong>629</strong><sub>/630</sub></h3>
</div>
</div>

<!-- targeta 2 -->
<div class="targetas marco">
<div class="graficosIcons">
<img src="../assets/rojo.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">
</div>

<div style="float: left;"><strong>Unidades Sin Reportar</strong>
<li :style="{ color: color('rosado')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>1</strong><sub>/630</sub></h3>
</div>
</div>

<!-- targeta 3 -->
<div class="targetas marco">
<div class="graficosIcons">
<img src="../assets/azul.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Evento Low Batery</strong>
<li :style="{ color: color('azulMarino')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>9</strong><sub>/630</sub></h3>
</div>
</div>

<!-- targeta 4 -->
<div class="targetas marco">
<div class="graficosIcons">
<img src="../assets/violeta.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Sensor movimiento</strong>
<li :style="{ color: color('violeta')[0]}" style="margin-left: -50%;"> </li>
<h3 style="float: left;"><strong>1</strong></h3>
</div>
</div>

<!-- targeta 5 -->
<div class="targetas marco">
<div class="graficosIcons">
<img src="../assets/amarillo.jpg" class="img-fluid zoomIcon" alt="Responsive image" width="85">      
</div>

<div style="float: left;"><strong>Eventos</strong>
<li :style="{ color: color('amarillo')[0]}"> </li>
<h3 style="float: left;"><strong>15</strong></h3>
</div>
</div> 

<!-- fin targetas -->




</div>
</div>
</div>




</div>

</div>
<div  @click="hidePop()" v-show="dashboardShowing"  class="trackerMap"> 

<div style="border-radius: 10px 10px 10px 10px; border:1px solid rgba(40, 41, 46, 5.0); position: relative; width: 100%; height: 100%;"
 >
<MapaPage />
</div>

 

<!-- <iframe
  src="https://maps.google.com/maps?q=18.4574411,-69.9278577&hl=es&z=14&amp;output=embed"
  width="100%" height="100%"
  style="border-radius: 10px 10px 10px 10px; border:1px solid rgba(40, 41, 46, 5.0);"
 >
</iframe>  -->

</div>


<div @click="hidePop()" v-if="historicoShowing" class="targetasContainer" style="padding-left: 30px; padding-right: 30px; ">
<div class="targetasGrafico">
<div class="marco areaGrafico">
<div class="d-flex" style="float: left;">
<span  style="float:right; margin: 15px;"><strong>Eventos Alertas</strong></span>
</div>
<div class="d-flex mt-2" style="float: right;">
<button @click="barChart()" type="button" class="btn rounded botonesGrafico"><i class="bi bi-bar-chart-line"></i></button>
<button @click="lineChart()" type="button" class="btn rounded botonesGrafico" style="margin-left: 5px;"><i class="bi bi-graph-up-arrow"></i></button>

<select class="form-select botonSelectGrafico">
  <option selected>Mes</option>
  <option value="1">Semana</option>
  <option value="2">anio</option>
  <option value="3">dia</option>
</select>

</div>

<GraficoComponet ref="GraficosMode"/>
 <div class="text-center" >
  <i class="bi bi-skip-start-fill"></i>
  <i class="bi bi-skip-end-fill"></i>
  <i :class="fijarMes" style="float: right;"></i>  
</div>
</div>
</div>



<div class="targetasHistorial">

<div class="areaHistorial">
<span><strong>Ultimos Eventos</strong></span>
<TablaEventos :listaAlertas="tablaTodo"/>
</div> 

</div>

</div>

</div>
</div>



</template>

<script setup>
 import { ref } from 'vue'
 import GraficoComponet from './GraficoComponet.vue'
 import MapaPage from './MapaPage.vue'

 import CrearUsuario from './CrearUsuario.vue'
 import ConfigDispositivo from './ConfigDispositivo.vue'

 import TablaEventos from  './TablaEventos.vue'
 import {color} from './tools'
 import {trackersDB,trackerstate, lastAlert, nEvent,notificationsTodo} from './DataConector.js' //




  let isOpen=ref(true);
  let popShowing=ref(false)
  let historicoShowing=ref(false);
  let dashboardShowing=ref(true);
  let alertShowing=ref(false)
  let settingShowing=ref(false);
  let chartTipe=ref('bi bi-bar-chart-line');
  let fijarMes=ref('bi bi-pin-fill')
  let GraficosMode=ref(null);
  let orientacionchart=ref(false);
  let tipochart=ref('line');

  let datosUser=ref({
    userName:"Pablito",
  })

  let detallesPop=ref({
    tituloPopBat:" ",
  })

  let config=ref({
    labelConfigShowing:false,
        usuario:{
          label:false,
          crearUserIsShowing:false
        },
        configDevice:{
          label:false,
          configDeviceIsShowing:false
        }
    

  })



  let datos=ref({
    trackers_db:0,
    trackersOffline:0,
    lowBattCount:0,
    movementCount:0,
    eventosCount:0,
  })

  let datosCajeros=ref([])

  let tablaBatery=ref()
  let tablaMove=ref()
  let tablaTodo=ref()
  let tablaShowing=ref()


  function updater(){


  if (window.$cookies.isKey('auth_popular')) {
    datosUser.value.userName=window.$cookies.get('auth_popular').userName
    console.log(window.$cookies.get('auth_popular'))

     trackersDB({client_id: window.$cookies.get('auth_popular').id_client}).then(resulado=>{



        lastAlert(window.$cookies.get('auth_popular').id_client).then(resul=>{
          console.log("ultima alert: ")
          console.log(resul)


          notificationsTodo(window.$cookies.get('auth_popular').id_client).then(resulTodo=>{
              if(resulTodo){
                tablaTodo.value=resulTodo
                datos.value.eventosCount=resulTodo.length

              }else{
                console.log("fallo al consultar todas las notificationsTodo")
              }
          })

          nEvent({ client_id:window.$cookies.get('auth_popular').id_client, event:'battery_off'}).then(resuEvent=>{
            console.log("Eventos Batery: ")
            if(resuEvent){

              tablaBatery.value=resuEvent

              datos.value.lowBattCount=resuEvent.length

            }else{
              console.log("Fallo al consultar battery_off")
            }


          nEvent({ client_id:window.$cookies.get('auth_popular').id_client, event:'input_change'}).then(resMove=>{

            
            
           if(resMove){
            tablaMove.value=resMove
            datos.value.movementCount=resMove.length

            }else{
              console.log("Fallo al consultar input_change")
            }
           
          })
           
          })

                    
        })

      if(resulado){
              datosCajeros.value=[]

        datos.value.trackersOffline=0

        resulado.forEach(elemTracker=>{

        trackerstate(elemTracker.id).then(resulState=>{
          if(resulState){

            datosCajeros.value.push({ tracker: elemTracker, state:resulState})
            if((resulState.state.connection_status) =='offline'){
              // console.log("hay unidad fuera de linea")
              datos.value.trackersOffline=datos.value.trackersOffline+1
            }else{
              console.log("fuera de linea")
            }
      

          }else{
            console.log("fallo el staate")
          }


        })

        })
        datos.value.trackers_db=resulado.length

      }
    
     })
  }
 
  }

  updater()

function showPopBate(){
  popShowing.value=true
  settingShowing.value=false
  detallesPop.value.tituloPopBat="Bateria baja"
  tablaShowing.value=tablaBatery.value
}

function showPopMove(){
  popShowing.value=true
  settingShowing.value=false
  detallesPop.value.tituloPopBat="movimiento"
  tablaShowing.value=tablaMove.value
}

function showPopTodo(){
  popShowing.value=true
  settingShowing.value=false

  detallesPop.value.tituloPopBat="Todas"
  tablaShowing.value=tablaTodo.value
}


function hidePop(){
   popShowing.value=false
   settingShowing.value=false
}

function verUbicacion(lat,lng){
  console.log(lat +" "+lng)
}


function openClose(){
  if(!isOpen.value){

     document.getElementById("mySidebar").style.minWidth = "260px";
      document.getElementById("mySidebar").style.width = "auto";
    isOpen.value=true;
    // iconAnual.value='bi bi-arrow-up';
    

  }else{
    
    document.getElementById("mySidebar").style.width = "3%";
    document.getElementById("mySidebar").style.minWidth = "11px";
    isOpen.value=false
    // iconAnual.value='bi bi-arrow-down'; 
  }

}

function crearUsuarioMenu(){
  
  setting()

  config.value.usuario.crearUserIsShowing=true
  config.value.usuario.label=true
  
  popShowing.value=true
  settingShowing.value=true

  config.value.configDevice.configDeviceIsShowing=false
  config.value.configDevice.label=false

}

function settingDeviceMenu(){

  setting()

  config.value.configDevice.configDeviceIsShowing=true
  config.value.configDevice.label=true

  popShowing.value=true
  settingShowing.value=true

  config.value.usuario.crearUserIsShowing=false
  config.value.usuario.label=false
}

function dashboard(){
dashboardShowing.value=true
historicoShowing.value=false
hidePop()
alertShowing.value=false;

config.value.labelConfigShowing=false
config.value.usuario.crearUserIsShowing=false
config.value.usuario.label=false
config.value.configDevice.configDeviceIsShowing=false
config.value.configDevice.label=false
}

function alerts(){
alertShowing.value=true;
hidePop()
historicoShowing.value=false
dashboardShowing.value=false

config.value.labelConfigShowing=false
config.value.usuario.crearUserIsShowing=false
config.value.usuario.label=false
config.value.configDevice.configDeviceIsShowing=false
config.value.configDevice.label=false
}

function historico(){
historicoShowing.value=true
dashboardShowing.value=false
hidePop()
alertShowing.value=false;

config.value.labelConfigShowing=false
config.value.usuario.crearUserIsShowing=false
config.value.usuario.label=false
config.value.configDevice.configDeviceIsShowing=false
config.value.configDevice.label=false
}

function setting(){
config.value.labelConfigShowing=true
dashboardShowing.value=false
historicoShowing.value=false
alertShowing.value=false;
}

function logOut(){
 window.$cookies.remove('auth_popular')
 window.location.reload();
}

function barChart(){
orientacionchart.value=false
chartTipe.value='bi bi-bar-chart-line';
tipochart.value='bar'; 
GraficosMode.value.updateGraficoGeneral(tipochart.value,"x")

}

function lineChart(){
  orientacionchart.value=true
  chartTipe.value='bi bi-graph-up-arrow';
  tipochart.value='line'; 
  GraficosMode.value.updateGraficoGeneral(tipochart.value,"x")
}


</script>

<style scoped>

.pagina{
  min-width: 1000px;
  background-color: #fafafa;
  height: 100vh;
  min-height: 750px;
/*  min-height: 1000px;*/
}


  .sidebar{
  transition: 0.5s;
  background-color: #1e1f24; /*yo*/
  box-shadow: rgba(0, 0, 0, 0.49) 0px 0px 19px 7px;
  min-width: 260px;
  /*font-size: max(0.5vw, 10px);*/
/*   box-shadow: rgba(0, 0, 0, 0.49) 0px 0px 30px 10px;*/
/*  height: 100% ;*/
}


.areaTrabajo{
  min-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height:100%;
  padding-bottom:15px;
  background-color:#141517 ;/*yo*/
}

  a:hover {
  background-color: rgb(13,110,253);
  
}

.mediciones{
  height: auto;
  width: 100%;
  background-color:#141517 ;/*yo*/
  padding-bottom:20px;
}

.trackerMap{
  height:100%;
  width: 100%;
  padding-left:40px;
  padding-right:40px;
  padding-bottom:30px;
}

.menuLogOut:hover{
cursor: pointer;
}

.targetasContainer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  background-color:#141517 ;/*yo*/
}
 .targetas{ 
  color: black;
  padding: 1%;
  min-height: 155px;
  min-width: 18%;
  font-size: max(1vw, 10px);
/*  font-size: 15px;*/
  position: relative;
}


.targetasGrafico{
  width: 70%;

}


.areaGrafico{

   margin-left: 15px;
   margin-right: 15px;
   height: 100%;
}

.targetasHistorial{

  position: relative;
  width: 30%;
  height: 100%;
  max-height: 650px;
  overflow: auto;
  border-radius: 10px 10px 10px 10px;
  border:1px solid rgba(40, 41, 46, 5.0);
  background-color: #1e1f24 !important;
  background-color: white;

}
*::-webkit-scrollbar {
  width: 5px;
  background-color: grey;
  border-radius: 10px;

}



.areaHistorial{
  padding:5%;
  color: white;
  overflow: auto;
  max-height: 100%;
  background-color: #1e1f24;
}


.marco{
  border-radius: 10px 10px 10px 10px;
  border:1px solid rgba(40, 41, 46, 5.0);
  background-color: #1e1f24;
  color: white;
  cursor: pointer;


}

.marco:hover{
  border-color: #553ba1;
}

.logoAurora{
  
    position:absolute;
    bottom: 4%;
    left: 15%;
  
  }

  .graficosIcons{
    position:absolute;
    bottom: 15%;
    right: 15px;
  }

  .botonesGrafico{
    color: white;
    border:1px solid rgba(70, 70, 70, 5.0);
  }
  .botonSelectGrafico{
    color: white; 
    outline: none; 
    background-color: #1e1f24; 
    margin-right: 5%; 
    margin-left: 5px;
    border:1px solid rgba(70, 70, 70, 5.0);
  }

  .iconPopular{
    height:32px;
    width:32px;
    margin-top:10px;
   
  }


  .iconos{
    margin-top:20%;
  }

  .pestanaActual{
    color:grey;
}

  .mlMenu{
    margin-left: 8px;
  }

  .cerrarSideBar{
    position:absolute;
    up: 3%;
    right: 3%;
/*    margin-left: 15%;*/

  }
  .menuTitles{
    font-size: calc(0.1em + 1vw);
  }

  .zoomIcon{
animation: zoom 5s infinite;

  }

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

  .popContainer{
  background-color: #1e1f24;
  position: absolute;
  color: white;
  width: 50%;
  min-height: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  border-radius: 15px 15px 15px 15px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 0px 30px 10px;
  }

/*  tabla*/

  .widget {
  display: block;
  position: relative;
  margin-bottom: 40px;
  padding: 15px;
  background: #222e3c;
  border-radius: 0.45rem;
  -webkit-box-shadow: var(--widget-shadow);
  box-shadow: var(--widget-shadow);
}

.widget-table-overflow {
  margin: 0 -15px -15px;
}

.widget>header {
  margin: -15px;
  padding: 15px;
}

table.table tbody {
  border-top: 1px solid #d7dfe6 !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #798892 !important;
}

.table {
  --bs-table-color: #ffffff;
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: #19222c;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #ffffff;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #ffffff;
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

  .columnaName  {
  position: sticky; 
  top: 0px;

 }

 .contenedorTabla {
max-height: 400px;
overflow: auto;
}


.negro{
  color: black;
}
</style>


