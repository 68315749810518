<template>
<canvas class="estilo" @click=" updateGraficoGeneral('bar','x');" id="myChart"></canvas>

</template>
<script setup>
import { color } from './tools.js';

import Chart from 'chart.js/auto'
import { defineExpose, onMounted } from 'vue'



let tipo='line';
let axis='x';


// ******crear grafico General********
let myChartGeneral;
let ctx;
async function crearGrafico(){
myChartGeneral = new Chart(ctx,{ });
}


//funcion 2
function setOrientacion(eje){
  if(tipo =='bar'){
    axis=eje
  }
}

//funcion 3
function settipoChart(tipochart){
  tipo=tipochart
}


//************** Datos *******************
let labels=['Diciembre','Enero','Febrero']

let allData=[
  { label: "Low Batery", valor: [34,243,34], color: color('rojo') },
  { label: "Movimiento", valor: [145,25,254], color: color('verde') },
  { label: "otro", valor: [123,5,134], color: color('azul') }

  ];
//************** Fin Datos *******************

//funcion 4
function updateGraficoGeneral(bar_line,eje){
  settipoChart(bar_line);
  setOrientacion(eje)


  if (myChartGeneral) {
    myChartGeneral.destroy();
  }
  myChartGeneral = new Chart(ctx,  configGrafico(tipo, axis, dataLoader( labels, allData )));

}

//************** DataLoader ******************
function dataLoader(label, dat){
let etiqueta=label;
let inputData=dat;

let dataset=[];

for (var i = 0; i < inputData.length; i++) {
dataset.push({
      axis: axis,
      label: inputData[i].label,
      data: inputData[i].valor,
      backgroundColor: inputData[i].color[0],
      borderColor:  inputData[i].color[1],
      borderWidth: 2,
      fill: false,
      tension: 0.1
     
    },)
}


const outputData = {
  labels: etiqueta,
  datasets: dataset
};


return outputData;

}
//------------ Fin DataLoader -------------------


//************** config grafico ******************

function configGrafico(tipe, eje, outputdata){

let datosInput=outputdata
let tipoGrafico=tipe
let giro=eje
// extra

const legendMargin={
  id:'legendMargin',
  beforeInit(chart){
    const fitValue=chart.legend.fit;

    chart.legend.fit=function fit(){
      fitValue.bind(chart.legend)();
      return this.height += 30
    }
  }
}

// config

const config = {
  type: tipoGrafico,
  data: datosInput,
  options: {
  onClick: (event, elements) => {
      if (elements[0]) {  

      switch (elements[0].datasetIndex) {
  case 0:
console.log(0);
    break;
  case 1:
console.log(1);
    break;
  // …
  case 2:
console.log(2);
    break;
  default:
console.log('no exite')
    break;
}         
      }
    },      
    indexAxis: giro,
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'titulo'
      },
        legend: {
    display: true,
    labels: {
    padding: 10,
    font: { size: 24, family:'vazir',},
    color: 'rgb(255, 255, 255)',

        }
        }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
      ticks: {
        color: 'rgb(255, 255, 255)', 
        font: {size: 27, family:'vazir',},
     
      },
        display: true,
        title: {
          display: true
        }
      },
      y: {
        ticks: {
          color: "white", 
          font: {size: 25, family:'vazir',},
              },
        display: true,
        title: {
          display: false,
          text: 'Value'
        },
        suggestedMin: 0,
        suggestedMax: 200
      }
    }
  },
   plugins:[legendMargin] //, customEvent
};

return config

}

//------------ Fin config grafico ----------------

// onMounted (constructores)
onMounted(async () => {
  ctx=document.getElementById('myChart');

  crearGrafico();
  updateGraficoGeneral("bar","x");
  //updateGraficoGeneral("bar","y");
  //updateGraficoGeneral("line","y");
  
  
  
})



defineExpose({updateGraficoGeneral});

</script>
<style scoped>
</style>
