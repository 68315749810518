<template>

<div class="row" style="display: flex;">
    <div class="col-7">


<div style="width: 100%; padding:30px; margin-top: 5%;">

  <!-- uno -->

<span class="titulosConfig">Seleccionar Dispositivo</span>

<div class="dropdown">
  <button class="btn border rounded-pill dropdown-toggle camposConfig" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{configPop.dispositivoSelected}}
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  <a  @click="changeNameDispositivo(data.tracker.label)" class="dropdown-item" href="#" v-for="(data, index)  in datos" :key="index">
    {{ data.tracker.label }}
  </a>
  </div>
</div>
  <!-- dos -->
<span class="titulosConfig">Cambio de nombre del equipo GPS</span>

<div class="border rounded-pill campoWriteName">
<input  :value="datos[0].tracker.label" placeholder="Escribir nuevo nombre" type="text" style=" background-color: transparent; color: grey; outline: none;  width: 100%; border:none"> 
</div>


<!-- cuatro -->
<span  class="titulosConfig">Grupo</span>

<div class="dropdown">
  <button class="btn border rounded-pill dropdown-toggle camposConfig" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    -Seleccionar-
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
  <a  @click="changeNameDispositivo('F-01')" class="dropdown-item" href="#" v-for="(data, index)  in datos" :key="index">
    {{ data.tracker.label }}
  </a>

  </div>
</div>



</div>



</div>


    <div class="col">
      <div style="display: flex; margin-left: 2%; margin-top: 8%;">Detalles Del Equipo</div>
      <hr>
      <div class="row mt-1">
        <div class="col">
   
                            <div class="titulosConfig">Nombre del equipo</div>
                            <div>Cajero Popular</div>
          
        </div>
        <div class="col">
                            <div class="titulosConfig">Estado</div>
                            <div style="color: red;">{{datos[0].state.state.connection_status}}</div>
          
        </div>
      </div>
            <div class="row mt-3">
        <div class="col">
   
                            <div class="titulosConfig">Ultima Conexion</div>
                            <div>Cajero Popular</div>
          
        </div>
        <div class="col">
                            <div class="titulosConfig">Grupo</div>
                            <div>Cajero Popular</div>
          
        </div>
      </div>
        <div class="row mt-3">
        <div class="col">
   
                            <div class="titulosConfig">Ultima Conexion</div>
                            <div>Cajero Popular</div>
          
        </div>
        <div class="col">
                            <div class="titulosConfig">N Eventos</div>
                            <div>Cajero Popular</div>
          
        </div>
      </div>



      
               
       

    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <button type="button"  style="background-color: grey; margin-right: 10px;" class="btn">Restaurar</button>
      <button type="button" class="btn btn-primary">Guardar</button>
      
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'

  export default {
  props:['datosCajeros'],
  components: {
      /*TablaDasboard */ 
  },
  setup(props){
    let datos=ref(props.datosCajeros)

    console.log(datos.value)

    let configPop=ref({
      dispositivoSelected:"-Seleccionar-",
      dispositivoName:"Cajero 2"
    })


      function changeSelectDispositivo(name){
        configPop.value.dispositivoSelected=name
      }
      function changeNameDispositivo(name){
        configPop.value.dispositivoSelected=name
      }


    return {datos,configPop,changeSelectDispositivo,changeNameDispositivo}
  },
     mounted() {
console.log("arranco")
  }
}

</script>

<style scoped>

  .titulosConfig{
   font-size: 12px;
     color: white; 
  padding-left: 10px;
  }

.camposConfig{

  background-color: white; 
  color: grey; 
  width: 100%; 
  text-align: left;
  min-height: 30px;
}

.campoWriteName{
  background-color: white; 
  color: black; 
  padding-left: 11px;  
  padding-top: 6px;
  padding-bottom: 6px;

}
	
</style>