export function mod(n) {
  let m=12;
  return ((n % m) + m) % m;
}

export function color(color){
    let colorInput=color
    let colorOutput=['rgba(54, 162, 235, 0.5)','rgb(54, 162, 235)'];

    if (colorInput=='azul') {
        colorOutput=['rgba(54, 162, 235, 0.5)','rgb(54, 162, 235)'];
    }

    if (colorInput=='rojo') {
        colorOutput=['rgba(215, 62, 62, 0.7)','rgb(195, 42, 42)'];
    }

    if (colorInput=='verde') {
        colorOutput=['rgba(75, 185, 134, 0.6)','rgb(25, 135, 84)'];
    }

      if (colorInput=='rosado') {
        colorOutput=['rgba(255, 0, 115, 1.0)','rgb(25, 135, 84)'];
    }


      if (colorInput=='verdeClaro') {
        colorOutput=['rgba(35, 188, 136, 1.0)','rgb(25, 135, 84)'];
    }

      if (colorInput=='celeste') {
        colorOutput=['rgba(75, 185, 134, 0.6)','rgb(25, 135, 84)'];
    }

      if (colorInput=='amarillo') {
        colorOutput=['rgba(251, 189, 83, 1.0)','rgb(25, 135, 84)'];
    }

        if (colorInput=='violeta') {
        colorOutput=['rgba(109, 75, 241, 1.0)','rgb(25, 135, 84)']

    }
         if (colorInput=='azulMarino') {
        colorOutput=['rgba(34, 126, 249, 1.0)','rgb(25, 135, 84)']
    }






    return colorOutput
}
