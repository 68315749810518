<template>
<div class="mapcontainer">

<div class="contenedor">
<div  id="map"> 
</div>
<div  style="text-align:left ;" v-show="isEditing" id="mySidebar">

      <div class="row">
        <div class="col">
   
                            <div class="titulosConfig">Nombre del equipo</div>
                            <strong class="tituloFlotanteCajero" >{{atmShowing.label}}</strong>
          
        </div>
        <div class="col">
                            <div class="titulosConfig">Estado</div>
                            <strong style="color: Green;">{{atmShowing.conection}}</strong>
          
        </div>
      </div>
            <div class="row mt-3">

        <div class="col">
                            <div class="titulosConfig">ID</div>
                            <strong class="tituloFlotanteCajero">{{atmShowing.id}}</strong>
          
        </div>
        <div class="col">
   
                            <div class="titulosConfig">Ultima Conexion</div>
                            <strong  style="font-size: 13px;" class="tituloFlotanteCajero">{{new Date().toLocaleDateString()+" "+new Date().toLocaleTimeString()}}</strong>
          
        </div>
      </div>


<button id="open" class="d-none" @click="openNav()">&#9776;open</button>
<a id="close" href="javascript:void(0)" class="d-none" @click="closeNav()">&times;</a>
</div>
</div>
</div>
</template>
<script setup>
	//AIzaSyC6MrXVMKXdS7oc0I-5ZeCrl_YGwk9DUE4
	//AIzaSyCp9RxmdhmMwsOURXPq2wT0vMq22wjC7XU



const map = ref();
let center=ref(); 
let cajeros=ref([])
let crearMarker=ref(null);
let atm_Groups=ref([])
let isEditing=ref(false);
/*const markers=ref([]);*/
let atmShowing=ref({
  id:null,
  label:'N/A',
  conection:null
})

import { onMounted, ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import { trackerstate, trackersDB } from './DataConector'


const loader = new Loader({
  apiKey: 'AIzaSyC6MrXVMKXdS7oc0I-5ZeCrl_YGwk9DUE4',
  version: 'weekly'
})


//*****funcione ****
function openNav() {    
isEditing.value=true;
}

function closeNav() {
  map.value.setZoom(14);
  map.value.setCenter(center.value);
 isEditing.value=false;
}


function doclickOpen(){
  document.getElementById("open").click();
}
function doclickClose(){
  document.getElementById("close").click();
}


function update(){


  if (window.$cookies.isKey('auth_popular')) {

    trackersDB({client_id: window.$cookies.get('auth_popular').id_client}).then(animalito=>{

    cajeros.value=[]

     console.log(animalito)

    animalito.forEach(elemento=>{
    trackerstate(elemento.id).then(trackerState=>{

      setTimeout(function(){console.log("Listo");}, 500);

      /*console.log(trackerState)*/

    cajeros.value.push({
      id:elemento.id,
      label:elemento.label,
      conection:trackerState.state.connection_status,
      coordenadas:trackerState.state.gps.location
    })


    if(animalito.length==cajeros.value.length){

      cajeros.value.forEach(atm=>{
        let statusIcon=1

        if((atm.conection) =="offline"){
           statusIcon=2
        }

        crearMarker.value(atm,statusIcon)

      })



    }    

  }



  )})

  })

  }



}

onMounted(async () => {

atm_Groups.value=[]

await loader.load().then(google => {

center.value=new google.maps.LatLng(18.468025816718264, -69.93920818790205);

map.value = new google.maps.Map(document.getElementById('map'), {
        center: center.value,//{lat:18.468025816718264,lng:-69.95920818790205 } 
        zoom: 14,
        mapId:'46419e86ee4ee586', //  charli
       /* mapTypeControl: false,  //para quitar que puedan cambiar estilo de mapa
        streetViewControl: false  //para poner o quitar al hombresito*/
        disableDefaultUI: true,
      });

map.value.addListener("click", () => {
    doclickClose();
    map.value.setZoom(14);
    map.value.setCenter(center.value);
  });

crearMarker.value=(datosATM, tipo)=>{

  let iconoShowing=require('../assets/icon.gif')

  switch(tipo){
  case 1:
    iconoShowing= require('../assets/icon.gif')
    break;
  case 2:
    iconoShowing= require('../assets/rojo.gif')
    break;
  default:
    iconoShowing= require('../assets/icon.gif')
    break;
  }

  let lugar=[]
  lugar=new google.maps.Marker({
          position:new google.maps.LatLng(datosATM.coordenadas.lat,datosATM.coordenadas.lng),
          map:map.value,
          opacity:0.7,
          animation: google.maps.Animation.DROP,
          label:{ text: datosATM.label, fontSize: '12px', color: 'white', },
          icon:{ url: iconoShowing,scaledSize: { width:64, height: 64 } },
          title: " "+datosATM.label, //sombra 
      })

  lugar.addListener("click", () => {
  doclickOpen();

  console.log(datosATM)

  atmShowing.value.id=datosATM.id;
  atmShowing.value.label=datosATM.label;
  atmShowing.value.conection=datosATM.conection;

/*   pointName.value=datosColmado.label;
   addressFlotante.value=datosColmado.address;
   trackerColmadoCount.value=datosColmado.count;
   grupodometro.value=datosColmado.sum;
   document.getElementById("tituloObjeto").style.color = "#0c903d";
   document.getElementById("Flotantefoto").style.height = "auto";
   imgFlotante.value=require('../assets/tienda2.svg')
   map.value.setCenter(lugar.getPosition());
   map.value.setZoom(18);
   geocerca.setVisible(false)
    */
  });
  atm_Groups.value.push(lugar);
}

})

update();

})
	
</script>
<style scoped>

.mapcontainer{
    position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 10px 10px 10px 10px; 
    border:1px solid rgba(40, 41, 46, 5.0);
  }
 .contenedor{
  width: 100%;
  height: 100%;
  position: relative;
  }

#map {
  
    position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
#mySidebar{
position:absolute;
top: 20px;
right: 0px;
bottom: 40px;
padding-top: 20px;
padding-left: 15px;
min-width: 300px;
color: white;
background-color: rgba(43, 43, 43, 0.5);
box-shadow: rgba(0, 0, 0, 0.49) -8px 0px 9px 0px;
}


  .titulosConfig{
   font-size: 12px;
     color: white; 
  padding-left: 10px;
  }

  .tituloFlotanteCajero{
    color:#bbd7ff
  }






</style>