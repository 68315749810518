import axios from 'axios';

const ip='kolarealb.dev2.rastreo.gs';
const protocol='https';
const apiURl=`${protocol}://${ip}/`;
/*
const ip='10.0.0.228';
const protocol='http';
const port="9090"
const apiURl=`${protocol}://${ip}:${port}/`
*/


export async function auth(datosUser){

   let data;
 await axios.post(apiURl+'auth/',datosUser).then(response=>{
    data=response.data;
      
   }).catch(error => {
      console.log(error)

   });
   return data
  
}

export async function authInternal(datosUser){

   let data;
 await axios.post(apiURl+'internal/',datosUser).then(response=>{
    data=response.data;
      
   }).catch(error => {
      console.log(error)

   });
   return data
  
}



export async function trackersDB(params){
let data;

 await axios.post(apiURl+`popular/trackers/`,params).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error)
   });
    return data
}

export async function nEvent(params){
let data;

 await axios.post(apiURl+`popular/nevent/`,params).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error)
   });
    return data
}


export async function notificationsTodo(client_id){
let data;
 await axios.post(apiURl+`popular/notificationsTodo`,{client_id:client_id}).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error.response.data)
   });
    return data
}


export async function createUser(params){

let data;
 await axios.post(apiURl+`create/user`,params).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error.response.data)
   });
    return data
}


export async function lastAlert(client_id){
let data;
 await axios.post(apiURl+`popular/lastalert`,{client_id:client_id}).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error.response.data)
   });
    return data
}



export async function trackerstate(tracker_id){
let data;
 await axios.get(apiURl+`popular/trackerstate/${tracker_id}`).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error.response.data)
   });
    return data
}

