<template>


<div v-if="sucee" class="popContainer">

<i class="bi bi-check" style="font-size: 210px; color: #198754;"></i>
<h3 style="color: #198754; margin-top: -60px;"><strong>Guardado!</strong></h3>
		
</div>

<div class="crearUsuario text-center">
<img  src='../assets/logoPopular.svg' alt="Responsive image" style="width:10%; margin-top: -11px;">
<h1 style="color: #59b1db;"><strong>!Crear Usuario!</strong></h1>
<div class="container">
	<h5 style= "text-align: left;">Detalles Básicos</h5>


<form>
<div class="form-group">

<div class="row">
<div class="col-7">
<label for="exampleFormControlInput1">Nombre</label>
<input  type="text" class="border campoRedondo rounded-pill form-control" v-model="datosCrear.nombre" :class="{ vacio: camposvacios.nombre}" placeholder="Escribe tu nombre">
	
</div>


<div class="col text-center">


    <button class="botonItem">
    <i class="bi bi-camera-fill"></i>
    </button> 

	<div>Agregar Foto</div>
</div>
<div class="col-12">
<label for="exampleFormControlInput1">Dirección</label>
<input type="text" class="border campoRedondo rounded-pill form-control"  v-model="datosCrear.direccion" placeholder="Escribe tu nombre direccion actual">
	
</div>
<div class="col-6">
	
	<label for="exampleFormControlInput1">Usename</label>
<input type="text" class="border campoRedondo rounded-pill form-control" v-model="datosCrear.username"  :class="{ vacio: camposvacios.username}"  placeholder="Escribe tu nombre completo ">
</div>
<div class="col-6">
	
	<label >Rol</label>

<div class="dropdown">
  <button  class="btn border campoRedondo rounded-pill dropdown-toggle camposConfig" :class="{ vacio: camposvacios.rol}" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{ datosCrear.rol}}
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <a  @click="rol('Usuario')" class="dropdown-item" href="#">Usuario</a>
    <a  @click="rol('Administrador')" class="dropdown-item" href="#">Administrador</a>
    <a  @click="rol('Mirador')"  class="dropdown-item" href="#">Mirador</a>
  </div>
</div>
</div>

<div class="col-6">
	
<label for="exampleFormControlInput1">Correo</label>
<input type="email" class="border campoRedondo rounded-pill form-control" v-model="datosCrear.correo" placeholder="Escribe tu nombre completo ">
</div>
<div class="col-6">
	
	<label >Genero</label>
<div style="display: flex;">
	<div class="form-check" style="margin-right: 15px;">
  <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="genero.mujerPicked" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    Mujer
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="genero.hombrePicked" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Hombre
  </label>
</div>
	
</div>

</div>

<div class="col-6">
	
	<label for="exampleFormControlInput1">Teléfono 1</label>
<input type="Tel" class="border campoRedondo rounded-pill form-control"  v-model="datosCrear.telefono1" placeholder="Escribe tu numero de telefono">
</div>
<div class="col-6">
	
	<label >Contraseña</label>
<input type="password" class="border campoRedondo rounded-pill form-control"  v-model="datosCrear.pass" :class="{ vacio: camposvacios.pass}" placeholder="Escribe Nueva Contraseña">
</div>

<div class="col-12 mt-3 mb-4 text-center">
	<div v-if="false" class="btn" style="margin-right: 10px; background-color: grey;">Cancelar</div>
	<div @click="Guardar()" class="btn btn-primary">Guardar</div>
</div>



</div>


</div>

</form>
</div>

</div>
</template>
<script setup>
import { ref } from 'vue'
import {createUser} from './DataConector.js'


let genero=ref({
mujerPicked:"",
hombrePicked:""
})

let paso=true

let sucee=ref(false)

	let datosCrear=ref({
		nombre:"",
		direccion:"",
		username:"",
		rol:"Seleccionar",
		correo:"",
		genero:"",
		telefono1:"",
		pass:null
	})

		let camposvacios=ref({
		nombre:false,
		username:false,
		pass:false,
		rol:false
	})

		// id_client 321390             insertUser(id,id_client, first_name, last_name,'role',login, pass).then((resultAdding)=>{


	function Guardar(){

		if((datosCrear.value.nombre=="") || (datosCrear.value.rol=='Seleccionar')|| (datosCrear.value.username=="") || (datosCrear.value.pass==null) || (datosCrear.value.pass=="")){
			console.log("Name or username or Pass is Empty")
			evaluarError()
		}else{

		generoEval()

		let param=cargarDatos(datosCrear.value)

		createUser(param).then(resultAdding=>{
			console.log(resultAdding)
		})

		if(paso){
			sucee.value=true

	setTimeout(() => {
		sucee.value=false
	}, 1500);

		}else{
			console.log("no se pudo Guardar")
		}

		limpiar()


		}
	}

	function rol(name){
		datosCrear.value.rol=name
	}

	function generoEval(){
		if(genero.value.mujerPicked=='on'){
			datosCrear.value.genero="Mujer"
		}else{
			datosCrear.value.genero="Hombre"
		}

	}

	function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

	function cargarDatos(datosInput){

		let idRamdo=Math.round(getRandomArbitrary(1000, 9000))
		
		let datosOut={
			"id":idRamdo, 
			"id_client": window.$cookies.get('auth_popular').id_client, 
			"first_name":datosInput.nombre, 
			"last_name": "I", 
			"role": datosInput.rol, 
			"username":datosInput.username, 
			"pass": datosInput.pass 
		}
		return datosOut
	}

	function limpiar(){

		datosCrear.value={
			nombre:"",
			direccion:"",
			username:"",
			rol:"Seleccionar",
			correo:"",
			genero:"",
			telefono1:"",
			pass:null
		}

		camposvacios.value.nombre=false
		camposvacios.value.username=false
		camposvacios.value.pass=false
		camposvacios.value.rol=false
	}

	function evaluarError(){

		camposvacios.value.nombre=false
		camposvacios.value.username=false
		camposvacios.value.pass=false
		camposvacios.value.rol=false

		if(datosCrear.value.nombre==""){
			camposvacios.value.nombre=true
		}
		if(datosCrear.value.username==""){
			camposvacios.value.username=true
		}
		if(datosCrear.value.pass=="" || datosCrear.value.pass==null){
			camposvacios.value.pass=true
		}
		if(datosCrear.value.rol=='Seleccionar'){
			camposvacios.value.rol=true
		}
	}
	
</script>
<style scoped>

	.popContainer{
  background-color: #fff;
  position: absolute;
  color: white;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  border-radius: 15px 15px 15px 15px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.49) 0px 0px 30px 10px;
  }

	.botonItem{
  border-radius: 60px 60px 60px 60px;
  background-color: #051b60;
  box-shadow: rgba(17, 26, 95, 0.49) 0px 0px 9px 0px;
  color: white; 
  width: 50px;
  height: 50px;
  
}

.crearUsuario{
/*	background-color:white; */
background-image: linear-gradient(to bottom right,  white,#c9cad1);
	color: black; 
	width: 100%; 
	height: 100%;
	border-radius: 15px 15px 15px 15px;
	box-shadow: rgba(0, 0, 0, 0.49) 0px 0px 30px 10px;
}
.container{
	padding-left: 5%;
	padding-right: 5%;
}
form{
	text-align: left;
}

form label{
	padding-left: 20px;
	font-size: 13px;
}

.camposConfig{

  background-color: white; 
  color: grey; 
  width: 100%; 
  text-align: left

}

.vacio{
	border-color: red !important;
}

.campoRedondo{
	background-color: rgba(255, 255, 255, 0.5);
}


	
</style>