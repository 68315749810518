<template>
<table style="width: 100%; height: 100%;">
   <thead class="fijo" style="background-color: #1e1f24; height: 35px;">
    <tr>
      <th scope="col">Unidad</th>
      <th scope="col">Evento</th>
      <th scope="col">Hora</th>
    </tr>
  </thead>
  <tbody v-for="(indice, index)  in listaOrganizada" :key="index">

<tr class="text-center"  style="background-color: grey">
         <th scope="col"></th>
  <td class="text-center">{{new Date(indice[0].time).toLocaleDateString()}}</td>
      <th scope="col"></th>

</tr>

   <tr class="fila" v-for="(dato, index2)  in listaOrganizada[index]" :key="index2">
      <th class="unidadId" scope="row">{{dato.tracker_id}}</th>
      <td>{{dato.description}}</td>
      <td>{{new Date(dato.time).toLocaleTimeString()}}</td>
    </tr>

  </tbody>
</table>  
</template>
<script>

import { ref } from 'vue'

  export default {
  props:['listaAlertas'],
  components: {
      /*TablaDasboard */ 
  },
  setup(props){

   let fechas =ref( new Map())
   let listaOrganizada=ref([])

try{
    props.listaAlertas.forEach(elemLista=>{
        fechas.value.set(new Date(elemLista.time).toLocaleDateString(),new Date(elemLista.time).toLocaleDateString());
    })

    fechas.value.forEach(elemt=>{
        listaOrganizada.value.push( props.listaAlertas.filter(element=>{
            if ((new Date(element.time).toLocaleDateString()).match(elemt) ) {
                return true
            }
        }))
    });
}catch(err){
    console.log(err)
}
function translate(name){
    return name
}
return {translate,listaOrganizada}
},
     mounted() {
console.log("arranco")
  }
}



</script>
<style scoped>
    .fijo {
  position: sticky;  
  top: -25px;
 }

 .unidadId{
   cursor: pointer;
 }

 .fila:hover{
   color: #50a0e9;
 }


</style>